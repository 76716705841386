<template>
	<Modal width="500px">
		<template #modal-title>
			Save Changes
		</template>
		<template #modal-content>
			Are you sure you wish to save the changes made to {{ entityType }}?
		</template>
		<template #modal-footer>
			<SecondaryActionButton @click="$emit('cancel')">
				Cancel
			</SecondaryActionButton>
			<SecondaryActionButton @click="$emit('save-changes')">
				Save Changes
			</SecondaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from './modal.vue'
import SecondaryActionButton from './secondary-action-button.vue'
export default {
	components: { SecondaryActionButton, Modal },
	props: {
		entityName: String,
		entityType: String
	}
}
</script>
