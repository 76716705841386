<template>
	<div class="cassie-vertical-md">
		<div class="d-flex cassie-horizontal-sm">
			<SecondaryActionButton
				v-if="fullAccessPermissions && !editingRules"
				@click="editRules"
			>
				Edit Match Rules
			</SecondaryActionButton>
			<v-tooltip
				v-if="!editingRules"
				right
			>
				<template #activator="{ on }">
					<v-icon
						left
						v-on="on"
						@click.stop="$emit('open-help-modal')"
					>
						mdi-information
					</v-icon>
				</template>
				<span>
					Click to expand
				</span>
			</v-tooltip>
			<v-spacer />
			<PrimaryActionButton
				v-if="!editingRules && fullAccessPermissions"
				@click="addRule"
			>
				<v-icon left>
					mdi-plus
				</v-icon>
				Create
			</PrimaryActionButton>
		</div>
		<ValidationForm #default="{ invalid }">
			<SectionCard>
				<template #title>
					{{ 'Match Rules' | useLabels }}
				</template>
				<template #body>
					<DataTable
						:headers="filteredHeaders"
						:items="editMatchRules"
						sort-by="mergePass"
					>
						<template
							#item.mergePass="{ item }"
						>
							<TextField
								v-if="editingRules"
								:value="item.mergePass"
								small
								type="number"
								class="cassie-input-width-md"
								:rules="{required: true}"
								@change="updateDataSubjectRules(item.index, 'mergePass', $event)"
							/>
							<span v-else>
								{{ item.mergePass }}
							</span>
						</template>
						<template
							#item.fieldName="{ item }"
						>
							<Dropdown
								v-if="editingRules"
								:value="getFieldNameValue(item.fieldName)"
								:items="dataSubjectContactFieldItems"
								custom-sort
								small
								type="number"
								rules="required"
								@change="updateDataSubjectRules(item.index, 'fieldName', $event)"
							/>
							<span v-else>
								{{ item.fieldName }}
							</span>
						</template>
						<template #item.allowBlanks="{ item }">
							<v-switch
								v-if="editingRules"
								:input-value="item.allowBlanks"
								:disabled="!editingRules"
								class="center-data-table-toggle"
								@change="updateDataSubjectRules(item.index, 'allowBlanks', $event)"
							/>
							<span v-else>
								<v-icon>
									{{ item.allowBlanks ? 'mdi-check' : 'mdi-cross' }}
								</v-icon>
							</span>
						</template>
						<template #item.mustBeBlank="{ item }">
							<v-switch
								v-if="editingRules"
								:input-value="item.mustBeBlank"
								:disabled="!editingRules"
								class="center-data-table-toggle"
								@change="updateDataSubjectRules(item.index, 'mustBeBlank', $event)"
							/>
							<span v-else>
								<v-icon>
									{{ item.mustBeBlank ? 'mdi-check' : 'mdi-cross' }}
								</v-icon>
							</span>
						</template>
						<template #item.updateDetails="{ item }">
							<v-switch
								v-if="editingRules"
								:input-value="item.updateDetails"
								:disabled="!editingRules"
								class="center-data-table-toggle"
								@change="updateDataSubjectRules(item.index, 'updateDetails', $event)"
							/>
							<span v-else>
								<v-icon>
									{{ item.updateDetails ? 'mdi-check' : 'mdi-cross' }}
								</v-icon>
							</span>
						</template>
						<template #item.isActive="{ item }">
							<v-switch
								v-if="editingRules"
								:input-value="item.isActive"
								:disabled="!editingRules"
								class="center-data-table-toggle"
								@change="updateDataSubjectRules(item.index, 'isActive', $event)"
							/>
							<span v-else>
								<v-icon>
									{{ item.isActive ? 'mdi-check' : 'mdi-cross' }}
								</v-icon>
							</span>
						</template>
					</DataTable>
				</template>
			</SectionCard>
			<PageActionRow v-if="editingRules">
				<template #actions>
					<v-spacer />
					<div class="d-flex flex-row cassie-horizontal-md">
						<SecondaryActionButton @click="cancelChanges">
							Cancel
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="fullAccessPermissions"
							:disabled="invalid"
							@click="showSaveChangesModal = true"
						>
							Save
						</PrimaryActionButton>
					</div>
				</template>
			</PageActionRow>
		</ValidationForm>
		<validation-observer #default="{ invalid }">
			<Modal
				v-if="showAddModal && fullAccessPermissions"
				width="600px"
			>
				<template #modal-title>
					{{ showAddModal ? 'Add' : 'Edit' }} {{ 'Rule' | useLabels }}
				</template>
				<template #modal-content>
					<div class="cassie-vertical-md">
						<TextField
							v-model="formRule.mergePass"
							:label="'Pass *' | useLabels"
							type="number"
							tooltip-text="Set the Merge Pass number, Cassie runs through each Merge Pass sequentially"
							:rules="{required: true, regex: `^[1]?[0-${highestValueMergePass}]$`}"
							@input="updateDetailsBasedOnMergePass($event)"
						/>
						<Dropdown
							v-model="formRule.fieldName"
							label="Field Name *"
							:items="dataSubjectContactFieldItems"
							custom-sort
							tooltip-text="Select a Cassie Contact Field for this Merge Pass"
							rules="required"
						/>
						<div>
							<v-row dense>
								<v-col
									cols="6"
									dense
								>
									<Toggle
										:value.sync="formRule.allowBlanks"
										label="Allow Blanks *"
										aligned-toggles
										tooltip-text="Allow Blanks specifies that the field passed may be blank or not be passed within the request to Cassie"
									/>
								</v-col>
								<v-col
									cols="6"
									dense
								>
									<Toggle
										:value.sync="formRule.mustBeBlank"
										label="Must be Blank *"
										aligned-toggles
										tooltip-text="Must be Blank allows a check to ensure that any Unique Identifier must be blank to allow the rule to run"
									/>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col
									cols="6"
									dense
								>
									<Toggle
										:value.sync="formRule.updateDetails"
										:disabled="disableUpdateDetailsField"
										label="Update Details *"
										aligned-toggles
										tooltip-text="If Update Details is enabled, any match on this Merge Pass will update the Cassie Contact Details with the detail provided in the request"
									/>
								</v-col>
								<v-col
									cols="6"
									dense
								>
									<Toggle
										:value.sync="formRule.isActive"
										label="Is Active *"
										aligned-toggles
										tooltip-text="A toggle to enable/disable this specific rule in the Merge Pass"
									/>
								</v-col>
							</v-row>
						</div>
					</div>
				</template>
				<template #modal-footer>
					<SecondaryActionButton @click="closeSubmitModal">
						Cancel
					</SecondaryActionButton>
					<PrimaryActionButton
						v-if="fullAccessPermissions"
						:disabled="invalid"
						@click="addMatchingRule"
					>
						Create
					</PrimaryActionButton>
				</template>
			</Modal>
		</validation-observer>
		<ConfirmSaveChangesModal
			v-if="showSaveChangesModal"
			entity-name="Changes Made"
			:entity-type="`${typeName} Data Subject Matching Rules`"
			@cancel="showSaveChangesModal = false"
			@save-changes="saveChanges"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import DataTable from '../../../../../../shared/components/data-table.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Modal from '../../../../../../shared/components/modal.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { MATCHING_RULES_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import ConfirmSaveChangesModal from '../../../../../../shared/components/confirm-save-changes-modal.vue'
import { getDataSubjectContactFields, upsertMatchingRules } from '../../../../../../shared/utils/api/data-subject-rules.js'

export default {
	components: {
		DataTable,
		SecondaryActionButton,
		SectionCard,
		Modal,
		TextField,
		PrimaryActionButton,
		ConfirmSaveChangesModal,
		PageActionRow,
		Dropdown,
		Toggle,
		ValidationForm
	},
	props: {
		matchRules: {
			type: Array,
			default: () => []
		},
		tableHeaders: {
			type: Array,
			default: () => []
		},
		typeName: String,
		paramType: String
	},
	data () {
		return {
			editMatchRules: [],
			editingRules: false,
			showAddModal: false,
			formRule: null,
			showSaveChangesModal: false,
			dataSubjectContactFields: [],
			disableUpdateDetailsField: false
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		fullAccessPermissions () {
			return this.checkPermission(MATCHING_RULES_FULL_PERMISSIONS)
		},
		filteredHeaders () {
			const tableHeaders = this.tableHeaders
			if (!this.editingRules) {
				return tableHeaders.filter(({ value }) => value !== 'action')
			} else {
				return tableHeaders
			}
		},
		dataSubjectContactFieldItems () {
			return this.dataSubjectContactFields.map(field => {
				return field.columnName
			})
		},
		highestValueMergePass () {
			const rules = [...this.matchRules]
			const mergePassValues = rules.map(rule => {
				return parseInt(rule.mergePass)
			})
			return Math.max(...mergePassValues) + 1
		}
	},
	watch: {
		matchRules: function () {
			this.editMatchRules = [...this.matchRules]
		}
	},
	async created () {
		await this.loadDataSubjectContactFields()
		this.editMatchRules = [...this.matchRules]
	},
	methods: {
		async loadDataSubjectContactFields () {
			const data = await getDataSubjectContactFields()
			this.dataSubjectContactFields = data.standakFields
		},
		editRules () {
			this.editingRules = true
		},
		addRule () {
			this.showAddModal = true
			this.formRule = {
				id: null,
				mergePass: '',
				fieldName: '',
				allowBlanks: false,
				mustBeBlank: false,
				updateDetails: false,
				isActive: false
			}
		},
		closeSubmitModal () {
			this.showAddModal = null
			this.formRule = null
		},
		addMatchingRule () {
			const matchingRules = [
				...this.matchRules,
				this.formRule
			]
			this.upsertMatchingRules(matchingRules)
			this.showAddModal = false
		},
		updateDataSubjectRules (index, property, value) {
			if (property === 'updateDetails') {
				const editedRule = this.editMatchRules[index]
				const rulesWithMatchingMergePass = this.editMatchRules.filter(({ mergePass }) => mergePass === editedRule.mergePass)
				rulesWithMatchingMergePass.forEach(rule => {
					const ruleIndex = this.editMatchRules.findIndex(matchRule => matchRule === rule)
					this.$set(this.editMatchRules[ruleIndex], property, value)
				})
			} else {
				this.$set(this.editMatchRules[index], property, value)
			}
		},
		cancelChanges () {
			this.$emit('load-rules')
			this.editMatchRules = [...this.matchRules]
			this.showSaveChangesModal = false
			this.editingRules = false
			this.maxMergePass = 0
		},
		getFieldNameValue (fieldName) {
			return this.dataSubjectContactFieldItems.find(value => value.toLowerCase() === fieldName.toLowerCase())
		},
		updateDetailsBasedOnMergePass (value) {
			const rulesWithMatchingMergePass = this.editMatchRules.filter(({ mergePass }) => mergePass === parseInt(value))
			if (rulesWithMatchingMergePass.length) {
				this.formRule.updateDetails = rulesWithMatchingMergePass[0].updateDetails
				this.disableUpdateDetailsField = true
			} else {
				this.disableUpdateDetailsField = false
			}
		},
		async upsertMatchingRules (matchingRules) {
			await upsertMatchingRules(matchingRules, this.paramType)
			this.$emit('load-rules')
			showSnackbar('Changes saved successfully')
		},
		async saveChanges () {
			await upsertMatchingRules(this.editMatchRules, this.paramType)
			this.showSaveChangesModal = false
			this.editingRules = false
			this.$emit('load-rules')
			window.scrollTo(0, 0)
			showSnackbar('Changes saved successfully')
		}
	}
}
</script>
