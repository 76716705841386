import { configApi } from '../http-client.js'

export const getRules = requestType => {
	return configApi.get(`/api/DataSubjectRules/MatchingRules?requestType=${requestType}`).then(({ data }) => {
		return {
			matchRules: data?.map((rule, index) => ({
				...rule,
				index: index
			}))
		}
	})
}

export const getDataSubjectContactFields = () => {
	return configApi.get('/api/GetDataSubjectContactFields').then(({ data }) => ({
		standakFields: data.standakFields
	}))
}

export const upsertMatchingRules = (matchingRules, queryType) => {
	return configApi.post(`/api/DataSubjectRules/MatchingRulesUpsert?requestType=${queryType}`, matchingRules)
}
