<template>
	<ViewLayout>
		<template #header-title>
			Data Subject Matching Rules
		</template>
		<template #header-caption>
			View and update the matching relationship rules for your organisation's data subjects.
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<MatchRulesTab
						v-if="apiSubmission"
						:match-rules="apiSubmission"
						:table-headers="tableHeaders"
						type-name="API Submission"
						param-type="api"
						@load-rules="loadAllRules"
						@open-help-modal="showHelpModal = true"
					/>
				</template>
				<template #1>
					<MatchRulesTab
						v-if="dataLoader"
						:match-rules="dataLoader"
						:table-headers="tableHeaders"
						type-name="Data Loader"
						param-type="load"
						@load-rules="loadAllRules"
						@open-help-modal="showHelpModal = true"
					/>
				</template>
				<template #2>
					<MatchRulesTab
						v-if="preferenceWidgets"
						:match-rules="preferenceWidgets"
						:table-headers="tableHeaders"
						type-name="Preference Widgets"
						param-type="widget"
						@load-rules="loadAllRules"
						@open-help-modal="showHelpModal = true"
					/>
				</template>
			</TabItems>
			<Modal v-if="showHelpModal">
				<template #modal-title>
					Pass Configuration Help
				</template>
				<template #modal-title-action>
					<IconButton @click="showHelpModal = false">
						mdi-close
					</IconButton>
				</template>
				<template #modal-content>
					<div class="cassie-vertical-md black--text">
						<p class="text-subtitle-1">
							What Are Passes?
						</p>
						<div>
							Any passes you create will be run whenever user data is submitted into Cassie, you can set different passes to run depending on how the data was submitted into Cassie. Passes do different things depending on the type of pass it is:
							<ul>
								<li>
									Remove duplicate records (Match Pass)
								</li>
								<li>
									Identify which Profile and or Device records belong to which user and group them together (Group Pass)
								</li>
								<li>
									Add relationships between users (Relationship Pass)
								</li>
							</ul>
						</div>
						<p class="text-subtitle-1">
							How do I configure a Pass?
						</p>
						<p>
							Once you have selected the data source and pass type you must then configure the Pass to detect data that meets a given criteria for example, you may setup a Match Pass that uses the Unique ID you have for users. This will then take all of the Unique IDs in the data you are submitting and check them against the Unique IDs for the users that are already in Cassie. If it finds a match it will then be able to discard those incoming records so that Cassie doesn't create two user records for the same person.
						</p>
						<p class="text-subtitle-1">
							What are Field Checks?
						</p>
						<p>
							Passes must have at least one field configured and each field can be set to do one of two checks and only incoming data that means all the fields checks result in TRUE will be affected by the Pass.
						</p>
						<p class="text-subtitle-2">
							'Must Match' Check:
						</p>
						<div>
							This means that both the Incoming Value and the Existing Value have a value i.e. aren't blank, and their values are the same.
							Examples:
							<ul>
								<li>
									<span style="color: green;">
										TRUE
									</span>: Existing Name = 'Daniel' & Incoming Name = 'Daniel'
								</li>
								<li>
									<span style="color: darkred;">
										FALSE
									</span>: Existing Name = 'Dan' & Incoming Name = 'Daniel'
								</li>
								<li>
									<span style="color: darkred;">
										FALSE
									</span>: Existing Name = ' ' & Incoming Name = ' '
								</li>
							</ul>
						</div>
						<p class="text-subtitle-2">
							'Must Not Match' Check:
						</p>
						<div>
							The means one of the following must be true:
							<ul>
								<li>
									The Existing Value is empty but the Incoming Value is populated
								</li>
								<li>
									The Existing Value is populated but the Incoming Value is empty
								</li>
								<li>
									Both the Existing Value and the Incoming Value are empty
								</li>
							</ul>
						</div>
						<div>
							Examples:
							<ul>
								<li>
									<span style="color: green;">
										TRUE
									</span>: Existing Name = ' ' & Incoming Name = 'Daniel'
								</li>
								<li>
									<span style="color: green;">
										TRUE
									</span>: Existing Name = 'Daniel' & Incoming Name = ' '
								</li>
								<li>
									<span style="color: green;">
										TRUE
									</span>: Existing Name = ' ' & Incoming Name = ' '
								</li>
								<li>
									<span style="color: darkred;">
										FALSE
									</span>: Existing Name = 'Daniel' & Incoming Name = 'Daniel'
								</li>
							</ul>
						</div>
					</div>
				</template>
				<template #modal-footer>
					<SecondaryActionButton @click="showHelpModal = false">
						Close
					</SecondaryActionButton>
				</template>
			</Modal>
		</template>
	</ViewLayout>
</template>

<script>
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { getRules } from '../../../../../../shared/utils/api/data-subject-rules.js'
import Modal from '../../../../../../shared/components/modal.vue'
import MatchRulesTab from './data-subject-match-rules-tab.vue'

export default {
	components: { Modal, SecondaryActionButton, ViewLayout, TabItems, Tabs, MatchRulesTab, IconButton },
	props: {
	},
	data () {
		return {
			tabs: [{ title: 'API Submission', slot: '0' }, { title: 'Data Loader', slot: '1' }, { title: 'Preference Widgets', slot: '2' }],
			tab: '0',
			selectedSourceTypeId: null,
			showHelpModal: false,
			apiSubmission: [],
			dataLoader: [],
			preferenceWidgets: []
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ text: 'Merge Pass', value: 'mergePass', width: '16%' },
				{ text: 'Field Name', value: 'fieldName', sortable: false, width: '16%' },
				{ text: 'Allow Blanks', value: 'allowBlanks', align: 'center', sortable: false, width: '16%' },
				{ text: 'Must Be Blank', value: 'mustBeBlank', align: 'center', sortable: false, width: '16%' },
				{ text: 'Update Details', value: 'updateDetails', align: 'center', sortable: false, width: '16%' },
				{ text: 'Is Active', value: 'isActive', align: 'center', sortable: false, width: '16%' }
			]
		}
	},
	created () {
		this.loadAllRules()
	},
	methods: {
		async loadAllRules () {
			await this.loadApiSubmission()
			await this.loadDataLoader()
			await this.loadPreferenceWidgets()
		},
		async loadApiSubmission () {
			const { matchRules } = await getRules('api')
			this.apiSubmission = matchRules ?? []
		},
		async loadDataLoader () {
			const { matchRules } = await getRules('load')
			this.dataLoader = matchRules ?? []
		},
		async loadPreferenceWidgets () {
			const { matchRules } = await getRules('widget')
			this.preferenceWidgets = matchRules ?? []
		}
	}
}
</script>
